//
// Copyright 2023 DXOS.org
//

// @generated by protoc-gen-es v2.1.0 with parameter "target=ts,import_extension=js"
// @generated from file dxos/echo/filter.proto (package dxos.echo.filter, syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_protobuf_struct } from "@bufbuild/protobuf/wkt";
import type { PublicKey } from "../keys_pb.js";
import { file_dxos_keys } from "../keys_pb.js";
import type { Reference } from "./model/document_pb.js";
import { file_dxos_echo_model_document } from "./model/document_pb.js";
import type { JsonObject, Message } from "@bufbuild/protobuf";

/**
 * Describes the file dxos/echo/filter.proto.
 */
export const file_dxos_echo_filter: GenFile = /*@__PURE__*/
  fileDesc("ChZkeG9zL2VjaG8vZmlsdGVyLnByb3RvEhBkeG9zLmVjaG8uZmlsdGVyIsADCgxRdWVyeU9wdGlvbnMSEAoIc3BhY2VJZHMYBSADKAkSRgoHZGVsZXRlZBgBIAEoDjIwLmR4b3MuZWNoby5maWx0ZXIuUXVlcnlPcHRpb25zLlNob3dEZWxldGVkT3B0aW9uSACIAQESRwoNZGF0YV9sb2NhdGlvbhgEIAEoDjIrLmR4b3MuZWNoby5maWx0ZXIuUXVlcnlPcHRpb25zLkRhdGFMb2NhdGlvbkgBiAEBEi0KB2luY2x1ZGUYAyABKAsyFy5nb29nbGUucHJvdG9idWYuU3RydWN0SAKIAQESJAoGc3BhY2VzGAYgAygLMhQuZHhvcy5rZXlzLlB1YmxpY0tleRIOCgZtb2RlbHMYAiADKAkiTgoRU2hvd0RlbGV0ZWRPcHRpb24SEAoMSElERV9ERUxFVEVEEAASEAoMU0hPV19ERUxFVEVEEAESFQoRU0hPV19ERUxFVEVEX09OTFkQAiIuCgxEYXRhTG9jYXRpb24SBwoDQUxMEAASCQoFTE9DQUwQARIKCgZSRU1PVEUQAkIKCghfZGVsZXRlZEIQCg5fZGF0YV9sb2NhdGlvbkIKCghfaW5jbHVkZSLjAgoGRmlsdGVyEjAKCnByb3BlcnRpZXMYASABKAsyFy5nb29nbGUucHJvdG9idWYuU3RydWN0SACIAQESNgoEdHlwZRgCIAEoCzIjLmR4b3MuZWNoby5tb2RlbC5kb2N1bWVudC5SZWZlcmVuY2VIAYgBARISCgpvYmplY3RfaWRzGAggAygJEhEKBHRleHQYAyABKAlIAogBARIQCgNub3QYBCABKAhIA4gBARIlCgNhbmQYBSADKAsyGC5keG9zLmVjaG8uZmlsdGVyLkZpbHRlchIkCgJvchgGIAMoCzIYLmR4b3MuZWNoby5maWx0ZXIuRmlsdGVyEjQKB29wdGlvbnMYByABKAsyHi5keG9zLmVjaG8uZmlsdGVyLlF1ZXJ5T3B0aW9uc0gEiAEBQg0KC19wcm9wZXJ0aWVzQgcKBV90eXBlQgcKBV90ZXh0QgYKBF9ub3RCCgoIX29wdGlvbnNiBnByb3RvMw", [file_google_protobuf_struct, file_dxos_keys, file_dxos_echo_model_document]);

/**
 * @generated from message dxos.echo.filter.QueryOptions
 */
export type QueryOptions = Message<"dxos.echo.filter.QueryOptions"> & {
  /**
   * *
   * Query only in specific spaces.
   *
   * @generated from field: repeated string spaceIds = 5;
   */
  spaceIds: string[];

  /**
   * / Controls how deleted items are filtered.
   *
   * @generated from field: optional dxos.echo.filter.QueryOptions.ShowDeletedOption deleted = 1;
   */
  deleted?: QueryOptions_ShowDeletedOption;

  /**
   * *
   * Query only local spaces, or remote on agent.
   * @default `QueryOptions.DataLocation.LOCAL`
   *
   * @generated from field: optional dxos.echo.filter.QueryOptions.DataLocation data_location = 4;
   */
  dataLocation?: QueryOptions_DataLocation;

  /**
   * / References to inline when querying.
   *
   * @generated from field: optional google.protobuf.Struct include = 3;
   */
  include?: JsonObject;

  /**
   * *
   * Query only in specific spaces.
   * @deprecated Use `spaceIds` instead.
   *
   * @generated from field: repeated dxos.keys.PublicKey spaces = 6;
   */
  spaces: PublicKey[];

  /**
   * *
   * Filter by model.
   * @default * Only DocumentModel.
   * To querty all models, use `models: ["*"]`.
   * @deprecated
   *
   * @generated from field: repeated string models = 2;
   */
  models: string[];
};

/**
 * Describes the message dxos.echo.filter.QueryOptions.
 * Use `create(QueryOptionsSchema)` to create a new message.
 */
export const QueryOptionsSchema: GenMessage<QueryOptions> = /*@__PURE__*/
  messageDesc(file_dxos_echo_filter, 0);

/**
 * @generated from enum dxos.echo.filter.QueryOptions.ShowDeletedOption
 */
export enum QueryOptions_ShowDeletedOption {
  /**
   * / Do not return deleted items. Default behaviour.
   *
   * @generated from enum value: HIDE_DELETED = 0;
   */
  HIDE_DELETED = 0,

  /**
   * / Return deleted and regular items.
   *
   * @generated from enum value: SHOW_DELETED = 1;
   */
  SHOW_DELETED = 1,

  /**
   * / Return only deleted items.
   *
   * @generated from enum value: SHOW_DELETED_ONLY = 2;
   */
  SHOW_DELETED_ONLY = 2,
}

/**
 * Describes the enum dxos.echo.filter.QueryOptions.ShowDeletedOption.
 */
export const QueryOptions_ShowDeletedOptionSchema: GenEnum<QueryOptions_ShowDeletedOption> = /*@__PURE__*/
  enumDesc(file_dxos_echo_filter, 0, 0);

/**
 * @generated from enum dxos.echo.filter.QueryOptions.DataLocation
 */
export enum QueryOptions_DataLocation {
  /**
   * @generated from enum value: ALL = 0;
   */
  ALL = 0,

  /**
   * @generated from enum value: LOCAL = 1;
   */
  LOCAL = 1,

  /**
   * @generated from enum value: REMOTE = 2;
   */
  REMOTE = 2,
}

/**
 * Describes the enum dxos.echo.filter.QueryOptions.DataLocation.
 */
export const QueryOptions_DataLocationSchema: GenEnum<QueryOptions_DataLocation> = /*@__PURE__*/
  enumDesc(file_dxos_echo_filter, 0, 1);

/**
 * *
 * Protocol for ephemeral Filter serialization.
 *
 * @generated from message dxos.echo.filter.Filter
 */
export type Filter = Message<"dxos.echo.filter.Filter"> & {
  /**
   * @generated from field: optional google.protobuf.Struct properties = 1;
   */
  properties?: JsonObject;

  /**
   * @generated from field: optional dxos.echo.model.document.Reference type = 2;
   */
  type?: Reference;

  /**
   * @generated from field: repeated string object_ids = 8;
   */
  objectIds: string[];

  /**
   * @generated from field: optional string text = 3;
   */
  text?: string;

  /**
   * @generated from field: optional bool not = 4;
   */
  not?: boolean;

  /**
   * @generated from field: repeated dxos.echo.filter.Filter and = 5;
   */
  and: Filter[];

  /**
   * @generated from field: repeated dxos.echo.filter.Filter or = 6;
   */
  or: Filter[];

  /**
   * @generated from field: optional dxos.echo.filter.QueryOptions options = 7;
   */
  options?: QueryOptions;
};

/**
 * Describes the message dxos.echo.filter.Filter.
 * Use `create(FilterSchema)` to create a new message.
 */
export const FilterSchema: GenMessage<Filter> = /*@__PURE__*/
  messageDesc(file_dxos_echo_filter, 1);

